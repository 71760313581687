import type DataGridControl from 'o365.controls.DataGrid.ts';
import type GroupBy from 'o365.modules.DataObject.GroupBy.ts';

import utils from 'o365.modules.utils.js';
import { h } from 'vue';

export default class DataGridControlGroupByInterface {
    private _dataGridControl: DataGridControl;

    private get _groupByControl(): GroupBy {
        return this._dataGridControl.dataObject.groupBy;
    }

    private _groupBy: string[];
    private _groupsMaxReords: GroupByMaxRecordsSettings;
    private _groupByAggregates: any;
    /** Group column should be shown  */
    private _shouldShowColumn: boolean | null = null;

    get groupBy() { return this._groupBy; }
    get groupsMaxReords() { return this._groupsMaxReords; }
    get groupByAggregates() { return this._groupByAggregates; }

    constructor(dataGridControl: DataGridControl, options?: any) {
        this._dataGridControl = dataGridControl;

        this._groupBy = [];
        this._groupsMaxReords = {};
        this._groupByAggregates = {};

        //this._initGroupBy(options);
    }

    addGroup(colId: string) {
        if (!colId) { return; }
        const fieldName = this._getFieldFromColId(colId);
        this._setupMaxRecordsForField(colId);

        if (this._groupBy.length === 0) {
            this._showGroupColumn();
        }

        this._groupBy.push(colId);
        this._groupByControl.setGroupByForLevel([fieldName], this._groupBy.length - 1);

        if (this._groupBy.length === 1) {
            this._addAggregates();
            if (!!this.groupByAggregates[colId]) {
                this._updateAggregateHeaderName(colId, true);
            }
        } else if (!!this.groupByAggregates[colId]) {
            this._updateAggregateHeaderName(colId, true);
            this._groupByControl.removeGroupFromLevel(colId, 0);
        }

    }

    removeGroup(group: number | string) {
        if (group == null) { return; }

        let groupIndex: number;
        if (typeof group === 'number') {
            groupIndex = group;
        } else {
            groupIndex = this._groupBy.findIndex(x => x === group);
        }

        const colId = this._groupBy[groupIndex];

        this._groupBy.splice(groupIndex, 1);
        this._groupByControl.removeGroupByLevel(groupIndex);

        if (this._groupBy.length === 0) {
            this._hideGroupColumn();
        } else if (!!this.groupByAggregates[colId]) {
            this._updateAggregateHeaderName(colId);
            this._addAggregates();
        } else if (groupIndex === 0) {
            this._addAggregates();
        }

        // if (!!this.groupByAggregates[colId]) {
        //     this._updateAggregateHeaderName(colId);
        // }
    }

    setGroupBy(groups: string[]) {
        if (!groups && groups !== null) { return; }

        if (groups) {
            groups.forEach(colId => this._setupMaxRecordsForField(colId));
            this._groupBy = groups;
            this._groupByControl.setGroupBy(groups.map(colId => [this._getFieldFromColId(colId)]));
            this._showGroupColumn();
            this._addAggregates();
            // groups.forEach(colID => {
            //     if (!!this.groupByAggregates[colID]) {
            //         this._updateAggregateHeaderName(colID);
            //     }
            // });
        } else {
            this._groupBy.splice(0, this._groupBy.length);
            this._groupByControl.setGroupBy(null);
            this._hideGroupColumn();
        }
    }

    private _setupMaxRecordsForField(colId: string, options?: {
        autoMaxRecords?: boolean,
        maxRecords?: number
    }) {
        if (this._groupsMaxReords[colId]) { return; }

        if (!options) {
            options = {
                autoMaxRecords: true
            };
        }


        const autoMaxRecordsFunction = (scope: GroupByMaxRecordsSetting) => {
            const height = this._dataGridControl.container.clientHeight;
            return Math.max(Math.round(height / 34), 2);
        };
        const numberMaxRecordsFunction = (scope: GroupByMaxRecordsSetting) => scope.maxRecords;

        let maxRecordsFunction: () => number;
        let maxRecords: number;
        let maxRecordsMode: GroupByMaxRecordsMode;
        if (options.autoMaxRecords) {
            maxRecordsMode = GroupByMaxRecordsMode.AUTO;
            maxRecords = this._dataGridControl.dataObject.recordSource.maxRecords ?? 25;
            maxRecordsFunction = () => autoMaxRecordsFunction(null);
        } else {
            maxRecordsMode = GroupByMaxRecordsMode.NUMBER;
            maxRecords = options.maxRecords ?? this._dataGridControl.dataObject.recordSource.maxRecords ?? 25;
            maxRecordsFunction = () => maxRecords;
        }

        if (maxRecords === -1) { maxRecords = 25; }

        this._groupsMaxReords[colId] = {
            _autoFunc: autoMaxRecordsFunction,
            _numberFunc: numberMaxRecordsFunction,
            getterFunction: maxRecordsFunction,
            _maxRecords: maxRecords,
            _mode: maxRecordsMode,
            get mode() { return this._mode; },
            set mode(val) {
                if (val === GroupByMaxRecordsMode.AUTO) {
                    this.getterFunction = () => this._autoFunc(this);
                } else {
                    this.getterFunction = () => this._numberFunc(this);
                }
                this._mode = val;
            },
            get maxRecords() { return this._maxRecords; },
            set maxRecords(value) {
                this._maxRecords = value;
                this.getterFunction = () => this._maxRecords;
            }
        };
    }

    async _initGroupBy(options: any) {
        if (this._dataGridControl.dataObject.groupBy) { return; }
        if (!options) { options = {}; }

        const hasInitialGroupBy = !!options.initialGroupBy;

        const groupByModuleOptions = {
            initialGroupBy: options.initialGroupBy,
            skipLoad: null,
        };

        const groupByGridOptions = {
            headerTextSlot: options.headerTextSlot
                ? null
                : () => {
                    const caption = this._groupBy.length === 0 
                        ?  this._dataGridControl.dataObject.fields[options.displayField]?.caption ?? options.headerName ?? 'Group'
                        : options.headerName ?? 'Group';
                    const colMap = {};
                    this._dataGridControl.dataColumns.columns.forEach(column => {
                        colMap[column.colId] = column;
                    });
                    if (caption) {
                        return h('span', { class: 'text-truncate' }, [
                            caption,
                            this._groupBy.length === 0 
                                ? undefined
                                : h('sup', { class: 'ms-1' }, `(${this._groupBy.map(colId => colMap[colId].headerName).join(' / ')})`)
                        ]);
                    } else {
                        return h('span', { class: 'text-truncate' },
                            this._groupBy.map(colId => colMap[colId].headerName).join(' / ')
                            // this._dataGridControl.dataObject.groupBy.groupBy.map(level => colMap[level.find(x => !x.groupByAggregate).name].headerName)?.join(' / ')
                        );
                    }
                },
            headerName: options.headerName ?? 'Group',
            headerTitle: options.headerTitle ?? options.headerName ?? 'Group',
            filter: options.filter ?? false,
            filterField: options.filterField ?? undefined,
            width: options.width ?? 400,
            pinned: options.hasOwnProperty('pinned') ? options.pinned : null,
            format: options.format ?? undefined,
            cellTitle: options.cellTitle ?? undefined,
        };

        if (options.loadingBlockSizes) {
            Object.keys(options.loadingBlockSizes).forEach(key => {
                const maxRecordsOption = {
                    maxRecords: options.loadingBlockSizes[key],
                    autoMaxRecords: false,
                };
                this._setupMaxRecordsForField(key, maxRecordsOption);
            });
        }

        if (hasInitialGroupBy) {
            this._groupBy = options.initialGroupBy;
            options.initialGroupBy.forEach((field: string) => {
                this._setupMaxRecordsForField(field);

                const column = this._dataGridControl.dataColumns.getColumn(field);
                if (column) {
                    column._hide = true;
                }
            });

            groupByModuleOptions.skipLoad = true;
        }

        // Setup initial aggregates from column options
        this._dataGridControl.dataColumns.columns.filter(col => col.groupAggregate).forEach(col => {
            this._groupByAggregates[col.field] = { name: col.field, groupByAggregate: col.groupAggregate };
            if (col.groupAggregateFn) {
                this._groupByAggregates[col.field].groupByAggregateFn = col.groupAggregateFn;
            }
        });

        await this._dataGridControl.dataObject.enableGroupBy(groupByModuleOptions, options.clientSide);
        this._groupByControl.onStateChange(() => {
            this._dataGridControl.updateWatchTargetType();
        });
        if (options.clientSide) {
            this._dataGridControl.dataObject.groupBy['bindReactiveFormatFunction']();
        }

        if (hasInitialGroupBy) {
            this._addAggregates();
        }

        window.requestAnimationFrame(() => {
            this._dataGridControl.dataObject.load();
        });

        this._groupByControl.setPostExpandHandler(this._animateExpand.bind(this));
        this._groupByControl.setPostCollapseHandler(this._animateCollapse.bind(this));

        const lastDetailFormatFunction = (item: any, parentGroup: any, skip: number) => {
            const groupKey = parentGroup.o_groupKey;
            item.o_groupLoadNextPage = async (lastItem: any) => {
                delete lastItem.o_groupLoadNextPage;
                this._dataGridControl.dataObject.state.isNextPageLoading = true;
                const retrievedData = await this._groupByControl['_retrieveGroupDetails'](this._groupByControl['_storage'][groupKey].item, {
                    maxRecords: this._groupsMaxReords[this._groupBy[parentGroup.o_level]].getterFunction(),
                    skip: skip
                });
                this._dataGridControl.dataObject.state.isNextPageLoading = false;

                const index = this._dataGridControl.dataObject.data.findIndex((x: any) => (<any>x).index === parentGroup.index);
                this._dataGridControl.dataObject['_data'].splice(index + skip + 1, 0, ...retrievedData);
                this._groupByControl['_update']();
            };

        };

        this._groupByControl.setLastDetailFormatFunction(lastDetailFormatFunction);

        import('o365.vue.components.DataGrid.helpers.jsx').then(module => {
            const displayField = options.displayField;

            const getColIdFromRow = (row) => {
                if (!row.o_groupHeaderRow) { return null; }
                return this._groupBy[row.o_level];
                // return this._dataGridControl.dataObject.groupBy.groupBy.map(level => level.find(x => !x.groupByAggregate).name)[row.o_level];
            };

            const getColumnFromGroupRow = (row, colId = null) => {
                if (colId == null) {
                    colId = getColIdFromRow(row);
                }
                if (colId == null) { return null; }
                return this._dataGridControl.dataColumns.getColumn(colId);
            };

            const getFieldFromRow = (row) => {
                const colId = getColIdFromRow(row);
                return this._getFieldFromColId(colId);
            };

            const captionCache = {};
            const getCaptionFromRow = (row) => {
                const colId = getColIdFromRow(row);
                if (colId == null) { return null; }
                if (captionCache[colId]) { return captionCache[colId]; }
                const column = getColumnFromGroupRow(row, colId);
                if (column == null) { return null; }
                captionCache[colId] = column.caption;

                return captionCache[colId];
            };

            const getGroupLevelValue = (row: any) => {
                const col = getColumnFromGroupRow(row);
                if (col == null) {
                    return;
                } else {
                    return utils.format(row[col.field], col);
                }
            };

            const definition = {
                ...groupByGridOptions,
                colId: 'AutoGroupColumn',
                hide: !hasInitialGroupBy,
                // filterdropdown: ()=>'',
                cellRenderer: module.ExpandableCellRenderer,
                cellRendererParams: {
                    handleClick: (row, col) => {
                        if (row.o_loading) { return; }

                        if (row.o_expanded) {
                            this._dataGridControl.dataObject.groupBy.collapseDetails(row);
                        } else {
                            const maxRecords = this._groupsMaxReords[this._groupBy[row.o_level]].getterFunction();
                            this._dataGridControl.dataObject.groupBy.loadGroup(row, { maxRecords: maxRecords });
                        }
                    },
                    boldDisplay: options?.boldDisplay,
                    getLeftMargin: row => row.o_level * 24,
                    isExpandable: row => row.o_groupHeaderRow,
                    isCollapsed: row => !row.o_expanded,
                    isLoading: row => row.o_loading,
                    hasPrefix: options.showPrefix,
                    getPrefix: row => {
                        const caption = getCaptionFromRow(row);
                        return caption ? `${caption}: ` : null;
                    },
                    getDisplay: (row, col) => {
                        if (row.o_groupHeaderRow) {
                            return getGroupLevelValue(row) ?? '(blank)' + ` (${row.o_detailsCount})`;
                        } else {
                            return utils.format(row[displayField], col);
                        }
                        return row.o_groupHeaderRow
                            ? (row[getFieldFromRow(row)] ?? '(blank)') + ` (${row.o_detailsCount})`
                            : displayField ? row[displayField] : null;
                    },
                }
            };
            const indexToInsertAt = this._dataGridControl.dataColumns.columns.findIndex(x => !x.colId.startsWith('o365_'));
            this._dataGridControl.addColumn(definition, indexToInsertAt);
            if (this._shouldShowColumn) {
                const column = this._getGroupColumn();
                if (column) { column._hide = false; }
            }
        });
    }

    private _getGroupColumn() {
        return this._dataGridControl.dataColumns.getColumn('AutoGroupColumn');
    }

    private _showGroupColumn() {
        this._shouldShowColumn = true;
        const column = this._getGroupColumn();
        if (column) {
            column._hide = false;
        }

        // Set aggreagte header names
        if (this.groupByAggregates) {
            Object.keys(this.groupByAggregates).forEach(key => {
                this._updateAggregateHeaderName(key);
            });
        }
    }

    private _hideGroupColumn(pForceHide = false) {
        if (!pForceHide) { return; }
        this._shouldShowColumn = false;
        const column = this._getGroupColumn();
        if (column) {
            column._hide = true;
        }

        // Restore aggreagte header names
        if (this.groupByAggregates) {
            Object.keys(this.groupByAggregates).forEach(key => {
                this._updateAggregateHeaderName(key, true);
            });
        }
    }

    /** Helper function for updating aggregate header name */
    private _updateAggregateHeaderName(colId: string, unset = false) {
        if (this._groupBy.includes(colId) && !unset) { return; }
        const aggColumn = this._dataGridControl.dataColumns.getColumn(colId);
        if (aggColumn) {
            if (unset) {
                aggColumn.headerName = this.groupByAggregates[colId].originalHeaderName ?? aggColumn.headerName;
                aggColumn._aggregateEnabled = false;
                delete this.groupByAggregates[colId].originalHeaderName;
            } else if (this.groupByAggregates[colId].originalHeaderName == null) {
                this.groupByAggregates[colId].originalHeaderName = aggColumn.headerName;
                aggColumn.headerName = `${this.groupByAggregates[colId].groupByAggregate.toLocaleLowerCase()}(${aggColumn.headerName})`;
                aggColumn._aggregateEnabled = true;
            }
        }
    }

    private _animateExpand(fromIndex: number, count: number): Promise<void> {
        let resolveAnimation;
        const animationPromise = new Promise<void>(res => {
            resolveAnimation = res;
        });

        window.setTimeout(() => {
            const rows = Array.from(this._dataGridControl.container.querySelectorAll('.o365-body-row')).filter((x: HTMLElement) => parseInt(x.dataset.o365Rowindex ?? '') > fromIndex) as HTMLElement[];

            rows.forEach(row => {
                row.classList.add('expand-collapse-animation');
                const translate = row.style.transform.match(/\d+/) ? Number(row.style.transform.match(/\d+/)[0]) : null;
                row.style.transform = `translateY(${translate + (34 * count)}px)`;
            });

            setTimeout(() => {
                rows.forEach(row => {
                    row.classList.remove('expand-collapse-animation');
                });
                resolveAnimation();
            }, 310);
        }, 100);


        return animationPromise;
    }

    private _animateCollapse(fromIndex: number, count: number): Promise<void> {
        let resolveAnimation;
        const animationPromise = new Promise<void>(res => {
            resolveAnimation = res;
        });

        const doAnimation = () => {
            const rowsToRemove = Array.from(this._dataGridControl.container.querySelectorAll('.o365-body-row')).filter((x: HTMLElement) => {
                const index = parseInt(x.dataset.o365Rowindex ?? '');
                return index > fromIndex && index <= fromIndex + count;
            }) as HTMLElement[];

            const rowsToCollapse = Array.from(this._dataGridControl.container.querySelectorAll('.o365-body-row')).filter((x: HTMLElement) => parseInt(x.dataset.o365Rowindex ?? '') > fromIndex + count) as HTMLElement[];
            rowsToCollapse.forEach(row => {
                row.classList.add('expand-collapse-animation');
                const translate = row.style.transform.match(/\d+/) ? Number(row.style.transform.match(/\d+/)[0]) : null;
                row.style.transform = `translateY(${translate - (34 * count)}px)`;
            });

            rowsToRemove.forEach(row => row.style.display = 'none');

            window.setTimeout(() => {
                rowsToRemove.forEach(row => row.style.display = '');
                rowsToCollapse.forEach(row => {
                    row.classList.remove('expand-collapse-animation');
                });

                resolveAnimation();
            }, 310)
        };

        doAnimation();
        return animationPromise;
    }

    private _addAggregates() {
        if (this._groupByControl.groupBy.length === 0) { return; }
        Object.keys(this._groupByAggregates).forEach(key => {
            const index = this._groupByControl.groupBy[0].findIndex(x => x.name === key);
            if (index !== -1) { return; }
            this._groupByControl.addGroupToLevel(this._groupByAggregates[key], 0);
        });
    }

    /** Fields mapped to colIds */
    private _fieldsMap: Record<string, string> = {};
    /** ColId sets mapped to fields */
    private _colIdMap: Record<string, Set<string>> = {};
    /** Get field from colId */
    private _getFieldFromColId(colId: string) {
        if (colId == null) { return null; }
        if (this._fieldsMap[colId] == null) {
            const field = this._dataGridControl.dataColumns.getColumn(colId)?.field;
            this._fieldsMap[colId] = field;
            if (this._colIdMap[field] == null) { this._colIdMap[field] = new Set(); }
            this._colIdMap[field].add(colId);
        }
        return this._fieldsMap[colId];
    }

    getFieldFromColId(colId: string) {
        if (colId == null) { return null; }
        return this._fieldsMap[colId];
    }
}

export enum GroupByMaxRecordsMode {
    AUTO = 'auto',
    NUMBER = 'number'
}

export interface GroupByMaxRecordsSetting {
    _autoFunc: (scope: GroupByMaxRecordsSetting) => number;
    _numberFunc: (scope: GroupByMaxRecordsSetting) => number;
    _maxRecords: number;
    _mode: GroupByMaxRecordsMode;
    getterFunction: () => number;
    mode: GroupByMaxRecordsMode;
    maxRecords: number;

}

interface GroupByMaxRecordsSettings {
    [key: string]: GroupByMaxRecordsSetting;
}